<template>
  <div>
    <PageHeader title="Kantoren" />
    <div class="tw-px-2.5 tw-pt-5 tw-pb-16 tw-mx-auto">
      <DataTable
        :loading="isLoading"
        :headers="headers"
        :paginate="false"
        :can-search="true"
        v-bind="officeData"
        @changedOrder="fetchOffices"
        @filter="fetchOffices"
      >
        <template #item-actions="{ item }">
          <a
            :href="item.url"
            class="tw-px-2.5 tw-py-0.5 tw-inline-block tw-rounded tw-text-white tw-font-semibold tw-bg-success hover:tw-bg-darken-success hover:tw-text-white"
          >
            Details
          </a>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import DataTable from '@/components/DataTable'
import { errorModal } from '@/modalMessages'
import { getOffices } from '@/services/organization'

export default {
  name: 'OrganizationOfficeList',
  components: {
    PageHeader,
    DataTable
  },
  data () {
    return {
      isLoading: false,
      // The officeData includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      officeData: {
        count: null,
        next: null,
        previous: null,
        results: []
      }
    }
  },
  computed: {
    headers () {
      return [
        { text: 'Referentie', value: 'reference', orderable: true, filterable: true },
        { text: 'Naam', value: 'name', orderable: true, filterable: true },
        { text: 'Acties', value: 'actions' }
      ]
    }
  },
  created () {
    this.fetchOffices()
  },
  methods: {
    async fetchOffices (payload) {
      try {
        this.isLoading = true
        const response = await getOffices(payload)
        this.officeData = response.data
      } catch {
        errorModal('Er ging iets mis bij het ophalen van de kantoren')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
